<template>
    <h4 class="select-title">
        {{ title }}
    </h4>
</template>
<script>
export default {
    name: "SelectTitle",
    props: {
        title: {
            type: String
        }
    }
}
</script>
<style lang="scss" scoped>
    .select-title {
        height: 75px;
        line-height: 75px;
        font-size: 14px;
        font-weight: 400;
        color: #9DA2A6;
        text-align: left;
    }
</style>
