<template>
    <div class="select-option">
        <div
            v-if="searchDropdownData && searchDropdownData.length > 0"
            class="select-option-wrapper"
        >
            <div
                v-for="(item, index) of searchDropdownData"
                :key="index"
            >
                <div class="tree-item">
                    <div class="input-item" @click.stop="handlerSelectTreeNodeClick($event, item)">
                        <input
                            class="input"
                            type="checkbox"
                            :id="item.id"
                            :checked="item.checked"
                        />
                        <label
                            class="label"
                            :for="item.id"
                            >{{ item.name }}</label
                        >
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            {{ searchOptionalLoadTitle }}
        </div>
    </div>
</template>
<script>
export default {
    name: "SelectOption",
    props: {
        searchOptionalLoadTitle: String,
        searchDropdownData: Array,
    },
    methods: {
        handlerSelectTreeNodeClick(e, item) {
            this.$emit("handlerSelectTreeNodeClick", {e: e, item: item});
        },
    }
};
</script>
<style lang="scss" scoped>
.select-option {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 8;
    width: 100%;
    min-height: 60px;
    max-width: 400px;
    overflow: auto;
    background-color: #ffffff;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    .select-option-wrapper {
        width: 100%;
        max-height: 280px;
        overflow-y: auto;

        .tree-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 8px 16px 8px 0px;
            .input-item {
                display: flex;
                align-items: center;
                .label {
                    margin-left: 8px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #2b2f33;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
